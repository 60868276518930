@import "../utilities/Styles.css";

#Topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--closed_sidebar_width);
  background-color: var(--c_accent2);
  border-bottom: var(--small_dim) solid var(--c_highlight);
}

#Title {
  font-size: var(--h1_font);
  margin-left: var(--medium_dim);
}

#User {
  display: flex;
  flex-direction: row;
}

#Site-name {
  color: var(--c_headtext);
}

#Version {
  color: var(--c_maintext);
}

#User-name {
  padding: var(--medium_dim) var(--large_dim);
  margin: var(--medium_dim);
  color: var(--c_headtext);
}

#Login,
#Profile {
  padding: var(--medium_dim) var(--large_dim);
  margin: var(--medium_dim);
  border: none;
  background-color: var(--c_highlight);
  color: var(--c_headtext);
  border-radius: var(--small_dim);
  transition: background-color 0.3s ease;
}

#Login:hover,
#Profile:hover {
  filter: brightness(var(--b_brightness)) saturate(var(--b_saturation));
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: 100;
}