@import "../utilities/Styles.css";

#Sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#Open-sidebar,
#Closed-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--c_main1);
  overflow: hidden;
}

#Open-sidebar {
  justify-content: space-between;
  width: var(--open_sidebar_width);
}

#Open-sidebar-top,
#Open-sidebar-bottom {
  flex: 0 0 var(--closed_sidebar_width);
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  color: var(--c_alttext);
  background-color: var(--c_accent1);
  padding: 0 var(--medium_dim);
}

#Open-sidebar-top {
  border-bottom: var(--small_dim) solid var(--c_highlight);
}

#Open-sidebar-top button,
#Open-sidebar-bottom button,
#Closed-sidebar button {
  padding: 0;
  margin: 0;
  width: var(--small_button);
  height: var(--small_button);
  border: none;
  background-color: var(--c_accent1);
  vertical-align: top;
}

#Closed-sidebar button {
  border-radius: 0;
  background-color: var(--c_main1);
}

#Closed-sidebar-top button,
#Closed-sidebar-bottom button {
  background-color: var(--c_accent1);
}

#Open-sidebar img,
#Closed-sidebar img {
  width: var(--small_img);
  height: var(--small_img);
}

#Closed-sidebar {
  width: var(--closed_sidebar_width);
}

#Closed-sidebar-top {
  display: flex;
  align-items: flex-start;
  border-bottom: var(--small_dim) solid var(--c_highlight);
}

#Closed-sidebar-bottom {
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  border-top: var(--small_dim) solid var(--c_highlight);
}

#Open-sidebar-bottom {
  border-top: var(--small_dim) solid var(--c_highlight);
}

#Sidebar button:hover {
  filter: brightness(var(--b_brightness)) saturate(var(--b_saturation));
}

#Open-sidebar,
#Closed-sidebar {
  transition: width 0.3s ease, background-color 0.3s ease;
}

#Sidebar button {
  transition: background-color 0.3s ease;
}

#Article-list {
  width: 100%;
}

#Sidebar button {
  padding: 2px;
}