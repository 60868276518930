#Article-list {
  height: 100%;
  overflow-y: auto;
  padding-right: var(--large_dim);
}

#Article-list ul,
#Article-list li {
  padding-left: var(--small_dim);
  margin-left: var(--small_dim);
}

.Article-item {
  text-align: left;
  padding: var(--small_dim);
  margin: 0;
  background-color: var(--c_main1);
  color: var(--c_maintext);
  border: none;
  border-radius: var(--small_dim);
  cursor: pointer;
  list-style-type: none;
  margin-bottom: var(--medium_dim);
  position: relative;
}

.Article-item:hover {
  filter: brightness(var(--b_brightness)) saturate(var(--b_saturation));
}

.Article-delete-button {
  display: none; /* Initially hide the delete button */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--small_dim);
  background-color: transparent;
  border: none;
  border-radius: var(--small_dim);
  margin: 0;
  margin-right: var(--medium_dim);
  padding: 0;
  height: var(--mini_button);
  width: var(--mini_button);
  cursor: pointer;
}

.Article-delete-button img {
  background-color: none;
  height: var(--mini_button) !important;
  width: var(--mini_button) !important;
}

.Article-item:hover .Article-delete-button {
  display: flex; /* Show the delete button when hovering over the list item */
  align-items: center;
  justify-content: center;
}

.Article-title {
  font-size: var(--h2_font);
  font-weight: bold;
}

.Article-summary {
  font-size: var(--sub_font);
  color: var(--c_subtext);
  word-wrap: break-word;
  white-space: pre-line;
}